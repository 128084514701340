<template>
  <div>
    <div class="card border-primary mx-auto" style="max-width: 100%">
      <div class="card-body">
        <a-alert
          class="mb-2"
          :message="
            $t('cantine.dateChoisie') +
            `: ${selectedValue && selectedValue.format('YYYY-MM-DD')}`
          "
        />
        <div class="row">
          <div class="col-xl-4 col-lg-4">
            <div class="card">
              <div class="card-header">
                {{ $t("cantine.choisirDate") }}
              </div>
              <div class="card-body">
                <div
                  :style="{
                    border: '1px #d9d9d9',
                    borderRadius: '4px',
                  }"
                >
                  <a-calendar
                    :value="value"
                    @select="onSelect"
                    @panelChange="onPanelChange"
                    :fullscreen="false"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-8">
            <a-tooltip placement="right">
              <template slot="title">
                {{ $t("cantine.ajouterRepas") }}
              </template>

              <a-button
                class="mb-2"
                type="primary"
                icon="search"
                style="width: 100%"
                @click="showDrawer('add')"
              >
                {{ $t("cantine.ajouterRepas") }}
              </a-button>
            </a-tooltip>
            <div class="card">
              <div class="card-body">
                <div :class="$style.items">
                  <div v-if="filtredData.length === 0">
                    <div class="text-gray-6">
                      <div>
                        {{ $t("cantine.menuIntrouvable") }}
                      </div>
                    </div>
                  </div>
                  <div
                    :class="$style.item"
                    v-else
                    v-for="(item, index) in filtredData"
                    :key="index"
                  >
                    <div :class="$style.itemContent">
                      <div :class="$style.itemControl">
                        <div :class="$style.itemControlContainer">
                          <a-button-group>
                            <a-button icon="edit" @click="editItem(item)" />
                            <a-popconfirm
                              title="Sure ?"
                              ok-text="Oui"
                              cancel-text="Non"
                              @confirm="deleteItem(item)"
                            >
                              <a-button icon="delete" />
                            </a-popconfirm>
                          </a-button-group>
                        </div>
                      </div>
                      <!--IMAGE HERE item.path -->

                      <img
                        :src="settings.base_url + '/' + item.file"
                        alt="Image"
                      />
                    </div>
                    <div class="text-gray-6">
                      <div>{{ item.category + " : " + item.name }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <a-drawer
      :title="
        $t('cantine.ajouterNewMenu', {
          date: selectedValue.format('YYYY-MM-DD'),
        })
      "
      :width="720"
      :visible="addDrawer"
      :body-style="{ paddingBottom: '80px' }"
      @close="onClose('add')"
    >
      <a-form :form="addForm" layout="vertical">
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item :label="$t('profil.nom')">
              <a-input
                v-decorator="[
                  'Name',
                  {
                    rules: [{ required: true, message: $t('requis.repas') }],
                  },
                ]"
                :placeholder="$t('requis.repas')"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item label="Type">
              <a-select
                v-decorator="[
                  'category',
                  {
                    rules: [
                      { required: false, message: $t('cantine.saisirType') },
                    ],
                  },
                ]"
                :placeholder="$t('cantine.saisirType')"
              >
                <a-select-option value="dessert">
                  {{ $t("cantine.dessert") }}
                </a-select-option>
                <a-select-option value="platP">
                  {{ $t("cantine.plat") }}
                </a-select-option>
                <a-select-option value="boissons">
                  {{ $t("cantine.boissons") }}
                </a-select-option>
                <a-select-option value="autre">
                  {{ $t("cantine.autre") }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item :label="$t('cantine.repeter')">
              <a-select
                v-decorator="[
                  'repeat.days',
                  {
                    rules: [
                      {
                        required: false,
                        message: $t('cantine.choisirJour'),
                      },
                    ],
                  },
                ]"
                v-model="repeat"
                mode="multiple"
                :placeholder="$t('cantine.choisirJour')"
              >
                <a-select-option :value="1">
                  {{ moment().day(1).format("dddd") }}
                </a-select-option>
                <a-select-option :value="2">
                  {{ moment().day(2).format("dddd") }}
                </a-select-option>
                <a-select-option :value="3">
                  {{ moment().day(3).format("dddd") }}
                </a-select-option>
                <a-select-option :value="4">
                  {{ moment().day(4).format("dddd") }}
                </a-select-option>
                <a-select-option :value="5">
                  {{ moment().day(5).format("dddd") }}
                </a-select-option>
                <a-select-option :value="6">
                  {{ moment().day(6).format("dddd") }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12" v-if="repeat.length > 0">
            <a-form-item :label="$t('all.endDate')">
              <a-date-picker
                v-decorator="[
                  'repeat.endDate',
                  {
                    rules: [
                      { required: true, message: 'Saisir la date de fin' },
                    ],
                  },
                ]"
                placeholder="Saisir le nom du repas"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="16">
          <a-col :span="24">
            <a-upload-dragger
              v-decorator="[
                'photo',
                {
                  rules: [
                    {
                      required: false,
                    },
                  ],
                },
              ]"
              :multiple="false"
              :before-upload="beforeUpload"
              :file-list="fileList"
              :customRequest="addTofileList"
            >
              <p class="ant-upload-drag-icon">
                <a-icon type="file-image" />
              </p>
              <p class="ant-upload-text">
                {{ $t("etablissement.clickOrDragToUpload") }}
              </p>
            </a-upload-dragger>
          </a-col>
        </a-row>
      </a-form>
      <div
        :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button :style="{ marginRight: '8px' }" @click="onClose('add')">
          {{ $t("action.annuler") }}
        </a-button>
        <a-button
          type="primary"
          @click="handleSubmit"
          :loading="loadingAddBtn"
          :disabled="loadingAddBtn"
        >
          {{ $t("action.ajouter") }}
        </a-button>
      </div>
    </a-drawer>

    <!-- UPDATE DRAWER -->
    <a-drawer
      v-if="selectedMenu"
      :title="$t('cantine.modifierMenu', { name: selectedMenu.name })"
      :width="720"
      :visible="updateDrawer"
      :body-style="{ paddingBottom: '80px' }"
      @close="onClose('update')"
    >
      <a-form :form="form" layout="vertical">
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item :label="$t('profil.nom')">
              <a-input
                v-decorator="[
                  'Name',
                  {
                    initialValue: selectedMenu.name,
                    rules: [{ required: true, message: $t('requis.repas') }],
                  },
                ]"
                initialValue="record.name"
                :placeholder="$t('requis.repas')"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item :label="$t('cantine.type')">
              <a-select
                v-decorator="[
                  'category',
                  {
                    initialValue: selectedMenu.category,
                    rules: [
                      { required: false, message: $t('cantine.saisirType') },
                    ],
                  },
                ]"
                :placeholder="$t('cantine.saisirType')"
              >
                <a-select-option value="dessert">
                  {{ $t("cantine.dessert") }}
                </a-select-option>
                <a-select-option value="platP">
                  {{ $t("cantine.plat") }}
                </a-select-option>
                <a-select-option value="boissons">
                  {{ $t("cantine.boissons") }}
                </a-select-option>
                <a-select-option value="autre">
                  {{ $t("cantine.autre") }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item :label="$t('cantine.repeter')">
              <a-select
                v-decorator="[
                  'repeat.days',
                  {
                    initialValue: selectedMenu.repeat.days,
                    rules: [
                      {
                        required: false,
                        message: $t('cantine.choisirJour'),
                      },
                    ],
                  },
                ]"
                v-model="selectedMenu.repeat"
                mode="multiple"
                :placeholder="$t('cantine.choisirJour')"
              >
                <a-select-option :value="1">
                  {{ moment().day(1).format("dddd") }}
                </a-select-option>
                <a-select-option :value="2">
                  {{ moment().day(2).format("dddd") }}
                </a-select-option>
                <a-select-option :value="3">
                  {{ moment().day(3).format("dddd") }}
                </a-select-option>
                <a-select-option :value="4">
                  {{ moment().day(4).format("dddd") }}
                </a-select-option>
                <a-select-option :value="5">
                  {{ moment().day(5).format("dddd") }}
                </a-select-option>
                <a-select-option :value="6">
                  {{ moment().day(6).format("dddd") }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12" v-if="Object.keys(selectedMenu.repeat).length > 0">
            <a-form-item :label="$t('all.endDate')">
              <a-date-picker
                v-decorator="[
                  'repeat.endDate',
                  {
                    initialValue: selectedMenu.repeat.endDate,
                    rules: [{ required: true, message: $t('choisir.dateFin') }],
                  },
                ]"
                :placeholder="$t('cantine.saisirNom')"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="24">
            <a-upload-dragger
              v-decorator="[
                'photo',
                {
                  rules: [
                    {
                      required: false,
                    },
                  ],
                },
              ]"
              :multiple="false"
              :before-upload="beforeUpload"
              :file-list="fileList"
              :customRequest="addTofileList"
            >
              <p class="ant-upload-drag-icon">
                <a-icon type="file-image" />
              </p>
              <p class="ant-upload-text">
                {{ $t("etablissement.clickOrDragToUpload") }}
              </p>
            </a-upload-dragger>
          </a-col>
        </a-row>
      </a-form>
      <div
        :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button :style="{ marginRight: '8px' }" @click="onClose('update')">
          {{ $t("action.annuler") }}
        </a-button>
        <a-button type="primary" @click="handleUpdate">
          {{ $t("action.modifier") }}
        </a-button>
      </div>
    </a-drawer>
  </div>
</template>
<script>
import apiClient from "@/services/axios";
import { mapState } from "vuex";

import moment from "moment";
export default {
  data() {
    return {
      loading: false,
      data: [],
      value: moment(),
      selectedValue: moment(),
      selectedMenu: null,
      form: this.$form.createForm(this),
      addForm: this.$form.createForm(this),
      addDrawer: false,
      updateDrawer: false,
      repeat: [],
      fileList: [],
      loadingAddBtn: false,
    };
  },
  methods: {
    moment,
    onSelect(value) {
      this.value = value;
      this.selectedValue = value;
    },
    onPanelChange(value) {
      this.value = value;
    },
    showDrawer(type) {
      this.fileList = [];
      if (type == "add") {
        this.addForm.resetFields();
        this.addDrawer = true;
      } else if (type == "update") this.updateDrawer = true;
    },
    onClose(type) {
      if (type == "add") this.addDrawer = false;
      if (type == "update") this.updateDrawer = false;
    },
    handleSubmit(e) {
      e.preventDefault();
      this.addForm.validateFields((err, values) => {
        if (!err) {
          this.loadingAddBtn = true;
          values.date = this.value;

          let formData = new FormData();
          if (values.Name) formData.append("name", values.Name);
          if (values.category) formData.append("category", values.category);
          if (values.date) formData.append("date", values.date);
          if (this.fileList[0]) formData.append("file", this.fileList[0]);
          if (values.repeat)
            formData.append("repeat", JSON.stringify(values.repeat));

          apiClient({
            method: "put",
            url: "/cantine",
            data: formData,
            headers: {
              "Content-Type": `multipart/form-data;`,
            },
          })
            .then((res) => {
              console.log(res);
              this.getAllFood();
              this.onClose("add");
              this.$message.success("Un repas est ajouté.");
            })
            .catch((e) => {
              this.$message.error("Un erreur dans la modification !");
            })
            .finally(() => (this.loadingAddBtn = false));
        }
      });
    },
    handleUpdate(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          values.date = this.value;

          let formData = new FormData();
          if (values.Name) formData.append("name", values.Name);
          if (values.category) formData.append("category", values.category);
          if (values.date) formData.append("date", values.date);
          if (this.fileList[0]) formData.append("file", this.fileList[0]);
          if (values.repeat)
            formData.append("repeat", JSON.stringify(values.repeat));

          console.log(this.fileList[0]);

          apiClient({
            method: "patch",
            url: "/cantine/" + this.selectedMenu._id,
            data: formData,
            headers: {
              "Content-Type": `multipart/form-data;`,
            },
          })
            .then((res) => {
              this.getAllFood();
              this.onClose("update");
              this.$message.success("Le repas est modifié.");
            })
            .catch((e) => {
              this.$message.error("Un erreur dans l'ajout' !");
            });
        }
      });
    },
    //Update food
    editItem(item) {
      this.selectedMenu = item;
      this.showDrawer("update");
      console.log(this.form);
    },
    //Delete food
    deleteItem(item) {
      apiClient
        .delete("/cantine/" + item._id)
        .then((res) => {
          this.getAllFood();
        })
        .catch((e) => console.log(e));
    },
    //Get all foods
    getAllFood() {
      apiClient
        .post("/cantine/filter", { query: {} })
        .then((res) => {
          this.data = res.data;
        })
        .catch((e) => console.log(e));
    },
    beforeUpload(file) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        this.$message.error(
          "Vous ne pouvez télécharger que des fichiers JPG, JPEG ou PNG!"
        );
      }
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        this.$message.error("L'image doit être inférieure à 5 Mo!");
      }
      if (isJpgOrPng && isLt2M) {
        this.$message.success(`${file.name} fichier téléchargé avec succès..`);
        this.cantinePhoto = file;
      }
      return isJpgOrPng && isLt2M;
    },
    addTofileList() {
      this.fileList = [this.cantinePhoto];
    },
  },
  computed: {
    ...mapState(["settings"]),
    filtredData() {
      let data = this.data.filter(
        (food) =>
          moment(food.date).format("YYYYDDMM") == this.value.format("YYYYDDMM")
      );
      let repeats = this.data.filter((food) => {
        return (
          food.repeat &&
          this.value.isSameOrBefore(moment(food.repeat.endDate), "day") &&
          this.value.isSameOrAfter(moment(food.createdAt), "day") &&
          food.repeat.days.includes(this.value.day())
        );
      });

      repeats = repeats.concat(data);

      return [...new Set(repeats)];
    },
  },
  created() {
    // Get all foods
    this.getAllFood();
  },
};
</script>
<style lang="scss" module>
@import "./style.module.scss";
</style>
